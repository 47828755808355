var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('router-link',{attrs:{"to":"/apps/projects"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","svgClasses":"h-8 w-8"}})],1)],1),(
        _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.UserAdd) ||
          _vm.loginUserRole == 'Admin'
      )?_c('div',{staticClass:"vx-col w-full mb-base"},[_c('users-form')],1):_vm._e(),_c('div',{staticClass:"vx-col w-full"},[_c('vs-tabs',[(
            _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.UserView) ||
              _vm.permissionPageAccessArry.includes(
                _vm.PermissionVariables.UserDelete
              ) ||
              _vm.permissionPageAccessArry.includes(
                _vm.PermissionVariables.UserEdit
              ) ||
              _vm.permissionPageAccessArry.includes(
                _vm.PermissionVariables.UserExport
              ) ||
              _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"Users List","icon-pack":"feather","icon":"icon-box"}},[_c('div',{staticClass:"tab-text"},[_c('div',[_c('users-list')],1)])]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }