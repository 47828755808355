
<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive=true">
      <i class="text-center material-icons">delete</i>
    </span>
    <!-- <label class="switch">
      <input type="checkbox" @click="popupActive=true" :checked="value == '1'" />
      <span class="slider round"></span>
    </label>-->

    <!-- <b-form-checkbox
      @click.native="popupActive=true"
      value="1"
      unchecked-value="2"
      v-model="form.user_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>-->

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS'+params.data.user_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Delete user "
        :active.sync="popupActive"
      >
        <div class="text-cent er col-12">
          <h5>Do you want to delete {{params.data.user_email}} user ?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button color="success" type="filled" @click="onChangeCheckbox()" class="mr-3">Yes</vs-button>
              <vs-button color="danger" type="filled" @click="privStatus()">No</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import userService from "@/services/UserService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        user_active: "1"
      },
      copy_user_active: null
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_user_active = this.form.user_active;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodalS" + this.params.data.user_id);
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function() {
      this.$vs.loading();
      // this.value = this.value === "1" ? "2" : "1";
      let id = this.params.data.user_id;
      let payload = {
        // employment_name: this.params.data.employment_name,
        // company_id: this.params.data.employment_id,
        // description: this.params.data.description,
        user_active: this.form.user_active
      };
      //   console.log("payload, id", payload, id);
      userService
        .deleteUser(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            //console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
          setTimeout(x => {
            eventBus.$emit("refreshuserTable", data);
          }, 1000);
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.user_active = this.copy_user_active;
    }
  }
});
</script>
